import React, {useState} from 'react'
import {useAuthContext} from '../auth'
import {ReactComponent as LuxDevices} from '../shared/luxAsset1.svg';

type DeviceRegistrationProps = {
  onError: (msg: string, tryAgain: boolean) => void,
  onSuccess: (enrollmentUrl: string) => void
}

export function DeviceRegistration(props: DeviceRegistrationProps) {
  const [value, setValue] = useState('')

  const {provider} = useAuthContext()

  const getAuthToken = async () => {
    const token = (await provider.getAccessToken()).accessToken

    const response = await fetch(
      process.env.REACT_APP_ENROLLMENT_API + value + '/resubmiteligibility',
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )

    let jstring = await response.json()
    if(response.status == 404){
      console.log(jstring)
      props.onError("Device ID is not found", true)
    }
    else{
      try{
        let json = JSON.parse(jstring)
        let baseUrl = json[0].program.enrollmentUrl
        let urlPieces = baseUrl.split("?")
        urlPieces[0] = urlPieces[0] + "?iframe=true&"
        let url = urlPieces.join('')
        props.onSuccess(url);
      }
      catch (err){
        console.log(err);
        props.onError("Your device is not eligible for rebates at this time.", false);
      }
    }
  }

  return (
    <>
      <div className='SampleContainer'>
        <main className='page-main'>
          <div className='luxDevicesLogo'>
            <LuxDevices />
          </div>
          <label className='deviceId'>
            device ID:
          </label>
          <input
              type='text'
              value={value}
              onChange={e => setValue(e.target.value)}
              className='inputId'
            />
          <button onClick={getAuthToken} className='Button'>
            Enroll
          </button>
        </main>
      </div>
    </>
  )
}

import React, {useContext} from 'react'
import {MsalAuthProvider} from 'react-aad-msal'

export interface AuthContextShape {
  provider: MsalAuthProvider
}

export const defaultAuthContext: AuthContextShape = {
  provider: null,
}

export const AuthContext = React.createContext(defaultAuthContext)

export function useAuthContext() {
  return useContext(AuthContext)
}

import { Configuration } from "msal";

export type LoginConfig = {
  scopes: string[];
};

export type Config = {
  msal: Configuration;
  loginRequest: LoginConfig;
};

const tenant = process.env.REACT_APP_B2C_TENANT;
export const clientId = process.env.REACT_APP_B2C_CLIENT_ID;
const AzureADB2CHostname = process.env.REACT_APP_B2C_HOSTNAME;
const AuthorityBase = `https://${AzureADB2CHostname}/${tenant}`;
export const authority = `${AuthorityBase}/${process.env.REACT_APP_USER_FLOW}`;
export const readScope = process.env.REACT_APP_READ_SCOPE;

import React, {useState} from 'react'
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
} from 'react-aad-msal'
import {basicReduxStore} from './reduxStore'
import {useAuthContext} from './auth'

// Import the authentication provider which holds the default settings
import {authProvider, AuthContext} from './auth'
import {DeviceRegistration} from './Components'
import './App.css'

import {SampleBox} from './SampleBox'
import Iframe from 'react-iframe'
import {ReactComponent as LuxLogo} from './shared/LUX-Logo.svg'
import {ReactComponent as UserLogo} from './shared/user-icon.svg'
import {ReactComponent as ErrorLogo} from './shared/ErrorIcon.svg'
import {ReactComponent as LuxDevices} from './shared/luxAsset1.svg';
import { Modal } from './Components/ModalComponent'



export interface IValue {
  [key: string]: any
}

export interface IFormState {
  value: string
  accountInfo: any
  url: string
}

export default function App() {
  return (
    <div className='App'>
      <header className='page-header'>
          <div className='luxLogo'>
            <LuxLogo />
          </div>
      </header>
      <AzureAD provider={authProvider} reduxStore={basicReduxStore}>
        {({authenticationState}: IAzureADFunctionProps) => {
          const isAuthenticated =
            authenticationState === AuthenticationState.Authenticated
          return (
            <AuthContext.Provider value={{provider: authProvider}}>
              {isAuthenticated ? <AuthorizedApp /> : <UnauthorizedApp />
              }
            </AuthContext.Provider>
          )
        }}
      </AzureAD>
      <footer className='page-footer'>
          <label className='footerText'>
            @2018 All rights reserved. Lux Products Corporation | 
            <a href="https://www.johnsoncontrols.com/legal/terms"> Legal</a> | 
            <a href="https://www.johnsoncontrols.com/legal/privacy"> Privacy</a> | 
            <a href="https://www.luxproducts.com/sitemap/"> Sitemap</a> | 
            <a href="https://www.luxproducts.com/warranty/"> Warranty</a> | 
            <a href="https://www.luxproducts.com/recycling/"> Recycling</a> | 
            <a href="https://www.luxproducts.com/performance/"> Performance</a> | 
            <a href="https://www.johnsoncontrols.com/legal/patents"> Patents</a>
          </label>
        </footer>
    </div>
  )
}

export function UnauthorizedApp () {
  return(
    <>
      <div className='SampleContainer'>
        <main className="page-main-login">
          <div className='luxUserLogo'>
            <UserLogo />
          </div>
          {<SampleBox sampleType={'popup'} />}
      </main>
      </div>
    </>
  )
}

export function AuthorizedApp () {

  const [energyHubEnrollmentUrl, setEnergyHubEnrollmentUrl] = useState('')
  const [error, setError] = useState('')
  const [canTryAgain, setCanTryAgain] = useState(false);

  const {provider} = useAuthContext()

  async function onError (msg: string, tryAgain: boolean){
    await setError(msg)
    await setCanTryAgain(tryAgain)
  }

  async function onSuccessfulRegistration (url: string) {
    await setEnergyHubEnrollmentUrl(url)
  }

  async function onTryAgain (){
    await setError("")
  }

  async function onExitModal () {
    provider.logout()
  }
  
  return (
    <>
      {energyHubEnrollmentUrl && !error ? (
        <Iframe url={energyHubEnrollmentUrl} 
          id="myId"
          className="IFrame"
          position="absolute"
          // @ts-ignore
          sandbox="allow-scripts"
        />
      ) :
      <DeviceRegistration onError={onError} onSuccess={onSuccessfulRegistration}/>
     }
          <div id="lil-modal" className={`modal ${error ? "display" : ""}`}>
            <div className="modal-content">
              <div className="modal-header">
                <h1>ERROR</h1>
              </div>
              <div className="luxErrorLogo">
                <ErrorLogo />
              </div>
              <div className="modal-body">
                <p>{error}</p>
              </div>
              <div className="modal-footer">
                <button className="modal-btn exit" onClick={onExitModal}>Exit</button>
                {canTryAgain && <button className="modal-btn close"onClick={onTryAgain}>Try Again</button>}
              </div>
            </div>
          </div>
     
    </>
  )
}

import React from "react";
import SampleAppButtonLaunch from "./SampleAppButtonLaunch";

export const SampleBox: React.FC<{
  sampleType: string;
}> = ({ sampleType }) =>
  sampleType === "popup" ? (
    <div className="SampleBox">
      <p>Sign in to your account</p>
      <SampleAppButtonLaunch />
    </div>
  ) : (
      <div />
  );

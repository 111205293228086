// authProvider.js
import {MsalAuthProvider, LoginType} from 'react-aad-msal'
import {Configuration, AuthenticationParameters, Logger, LogLevel} from 'msal'

import {authority, clientId, readScope} from './config'

const logger = new Logger(
  (logLevel, message, containsPii) => {
    console.log('[MSAL]', message)
  },
  {
    level: LogLevel.Verbose,
    piiLoggingEnabled: false,
  },
)
// Msal Configurations
const config: Configuration = {
  auth: {
    authority: authority,
    clientId: clientId,
    // postLogoutRedirectUri: window.location.origin,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    validateAuthority: false,

    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true,
  },
  // Enable logging of MSAL events for easier troubleshooting.
  // This should be disabled in production builds.
  system: {
    logger: logger as any,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  },
}

// Authentication Parameters
const authenticationParameters: AuthenticationParameters = {
  scopes: ['openid', 'profile', readScope],
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
}

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options,
)
